import React from 'react';

import Seo from 'components/SEO';
import { Layout } from 'modules/ui';
import { HomePage } from 'modules/homepage';

import { pages } from '../constants';

const IndexPage = () => {
    const footerRef = React.useRef(null);

    return (
        <>
            <Seo pageId={pages.HOMEPAGE} />
            <Layout footerRef={footerRef}>
                <HomePage ref={footerRef} />
            </Layout>
        </>
    );
};

export default IndexPage;
